import { zodResolver } from '@hookform/resolvers/zod';
import { Auth } from 'aws-amplify';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, Input, toast } from '@polyphonic/ui';

type LoginInput = {
  email: string;
  password: string;
};

const LoginSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: 'An email / username is required' }),
  password: z
    .string()
    .min(1, { message: 'Required' })
    .min(8, 'Password is too short')
    .max(50, 'Password is too long'),
});

export const Login = () => {
  const {
    handleSubmit,
    register,

    formState: { errors },
  } = useForm<LoginInput>({
    resolver: zodResolver(LoginSchema),
    mode: 'onChange',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();

  const handleLogin: SubmitHandler<LoginInput> = async ({
    email,
    password,
  }) => {
    setIsSubmitting(true);
    await Auth.signIn({ username: email, password: password })
      .then(() => {
        toast.success('Login successful');
        setIsSubmitting(false);
        router.push('/dashboard');
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast.error(err);
      });
  };

  return (
    <>
      <Head>
        <title>Polyphonic | Login</title>
      </Head>
      <div className="flex flex-end items-center flex-col w-full p-4">
        <p className="text-center">Sign into your account</p>
        <form
          onSubmit={handleSubmit(handleLogin)}
          className="flex flex-col mt-4 w-full gap-4"
        >
          <Input
            variant="faded"
            id="email"
            label="Email"
            errorMessage={errors?.email?.message}
            {...register('email')}
            data-cy="username-input"
          />
          <Input
            variant="faded"
            id="password"
            label="Password"
            errorMessage={errors?.password?.message}
            {...register('password')}
            type="password"
            data-cy="password-input"
          />
          <Button
            className="w-full text-center shadow-morphic bg-primary rounded-full text-white font-bold mt-2"
            type="submit"
            data-cy="signin-button"
            isLoading={isSubmitting}
          >
            Login
          </Button>
        </form>
      </div>
    </>
  );
};
