/* eslint-disable no-console */
import { z } from 'zod';

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
const server = z.object({
  DATABASE_URL: z.string().url(),
  NODE_ENV: z.enum(['development', 'test', 'production']),
  MAILCHIMP_LIST_ID: z.string(),
  MAILCHIMP_SERVER: z.string(),
  MAILCHIMP_API_KEY: z.string(),
  ALCHEMY_API_KEY: z.string(),
  OPENAI_API_KEY: z.string(),
  OPENAI_API_ORG: z.string(),
  EDGE_CONFIG: z.string(),
  AWS_S3_BUCKET: z.enum(['polyphonic-dev', 'polyphonic-prod']),
  AWS_REGION: z.enum(['us-west-2', 'us-east-1']),
  AWS_ACCESS_KEY_ID: z.string(),
  AWS_SECRET_ACCESS_KEY: z.string(),
});

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
const client = z.object({
  NEXT_PUBLIC_AWS_REGION: z.enum(['us-west-2', 'us-east-1']),
  NEXT_PUBLIC_AWS_USER_POOLS_ID: z.enum([
    'us-east-1_TKe4oP3Jz',
    'us-east-1_wDvgEw4bV',
  ]),
  NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID: z.enum([
    'us-east-1:a103f186-afae-4202-ba57-005805683311',
    'us-east-1:d8bd32cb-fa41-48ca-a62b-f5794e3275d8',
  ]),
  NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID: z.enum([
    '2bhu44abg1m0mnvg3443q1or3k',
    '5nh7tvfnne25cq487r5qpf3nsa',
    'm7h59gsiov3r5oahl1004j4i5',
  ]),
  NEXT_PUBLIC_AWS_S3_BUCKET: z.enum(['polyphonic-dev', 'polyphonic-prod']),
  NEXT_PUBLIC_URL: z.string().min(1).url(),
  NEXT_PUBLIC_POLYLINK: z.string().min(1).url(),
  NEXT_PUBLIC_AWS_BUCKET_URL: z.string(),
});

/**
 * You can't destruct `process.env` as a regular object in the Next.js
 * edge runtimes (e.g. middlewares) or client-side so we need to destruct manually.
 * @type {Record<keyof z.infer<typeof server> | keyof z.infer<typeof client>, string | undefined>}
 */
const processEnv = {
  DATABASE_URL: process.env.DATABASE_URL,
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL,
  NEXT_PUBLIC_POLYLINK: process.env.NEXT_PUBLIC_POLYLINK,
  NEXT_PUBLIC_AWS_REGION: process.env.NEXT_PUBLIC_AWS_REGION,
  NEXT_PUBLIC_AWS_USER_POOLS_ID: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
  NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID:
    process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID,
  NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID:
    process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
  NEXT_PUBLIC_AWS_S3_BUCKET: process.env.NEXT_PUBLIC_AWS_S3_BUCKET,
  MAILCHIMP_LIST_ID: process.env.MAILCHIMP_LIST_ID,
  MAILCHIMP_SERVER: process.env.MAILCHIMP_SERVER,
  MAILCHIMP_API_KEY: process.env.MAILCHIMP_API_KEY,
  ALCHEMY_API_KEY: process.env.ALCHEMY_API_KEY,
  OPENAI_API_KEY: process.env.OPENAI_API_KEY,
  OPENAI_API_ORG: process.env.OPENAI_API_ORG,
  EDGE_CONFIG: process.env.EDGE_CONFIG,
  AWS_S3_BUCKET: process.env.AWS_S3_BUCKET,
  AWS_REGION: process.env.AWS_REGION,
  AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY,
  NEXT_PUBLIC_AWS_BUCKET_URL: `https://${process.env.NEXT_PUBLIC_AWS_S3_BUCKET}.s3.${process.env.NEXT_PUBLIC_AWS_REGION}.amazonaws.com/`,
};

// Don't touch the part below
// --------------------------

const merged = server.merge(client);
/** @type z.infer<merged>
 *  @ts-ignore - can't type this properly in jsdoc */
let env = process.env;

if (!!process.env.SKIP_ENV_VALIDATION == false) {
  const isServer = typeof window === 'undefined';

  const parsed = isServer
    ? merged.safeParse(processEnv) // on server we can validate all env vars
    : client.safeParse(processEnv); // on client we can only validate the ones that are exposed

  if (parsed.success === false) {
    console.error(
      '❌ Invalid environment variables:',
      parsed.error.flatten().fieldErrors,
    );
    throw new Error('Invalid environment variables');
  }

  /** @type z.infer<merged>
   *  @ts-ignore - can't type this properly in jsdoc */
  // eslint-disable-next-line no-undef
  env = new Proxy(parsed.data, {
    get(target, prop) {
      if (typeof prop !== 'string') return undefined;
      // Throw a descriptive error if a server-side env var is accessed on the client
      // Otherwise it would just be returning `undefined` and be annoying to debug
      if (!isServer && !prop.startsWith('NEXT_PUBLIC_'))
        throw new Error(
          process.env.NODE_ENV === 'production'
            ? '❌ Attempted to access a server-side environment variable on the client'
            : `❌ Attempted to access server-side environment variable '${prop}' on the client`,
        );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /*  @ts-ignore - can't type this properly in jsdoc */
      return target[prop];
    },
  });
}

export { env };
