/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-empty-function */
import { Auth, Hub } from 'aws-amplify';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import Loader from '@/components/common';
import { Onboarding } from '@/pages/auth/components';
import { authenticatedPaths } from '@/utils';

export interface IAuthContextType {
  user: any;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  unverifiedAccount: { email: string; password: string };
  signIn: (p: { email: string; password: string }) => Promise<any>;
  signOut: () => Promise<any>;
  signUp: (p: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => Promise<any>;
  confirmAccount: (p: { code: string }) => Promise<any>;
}

// Create a context object
export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  unverifiedAccount: {
    email: '',
    password: '',
  },
  signIn: async () => {},
  signOut: async () => {},
  signUp: async () => {},
  confirmAccount: async () => {},
});

interface IAuthProviderProps {
  children: React.ReactNode;
}

// Create a provider for components to consume and subscribe to changes
export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState<string | null>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [unverifiedAccount, setUnverifiedAccount] = useState({
    email: '',
    password: '',
  });

  const router = useRouter();

  /**
   * fetch currently logged-in user using AWS Auth library
   * @returns {Promise<void>}
   */
  const fetchAuthUser = async () => {
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setUsername(user.signInUserSession.idToken.payload.preferred_username);
      })
      .catch(() => {
        setUser(null);
        setUsername(null);
        if (authenticatedPaths.includes(router.pathname)) {
          router.push('/auth');
        }
      })
      .finally(() => {
        setIsAuthenticating(false);
      });
  };

  useEffect(() => {
    fetchAuthUser();

    // listening for auth change events
    const authListener = Hub.listen(
      'auth',
      async ({ payload: { event, data } }) => {
        console.log('Auth Status Changed Event: ', event);
        console.log('Auth Status Changed Data: ', data);
        switch (event) {
          case 'signIn':
            await fetchAuthUser();
            break;
          case 'signOut':
            setUser(null);
            break;
          case 'signIn_failure':
          case 'signUp_failure':
            if (user) {
              setUser(null);
            }
            break;
          case 'signUp':
          case 'forgotPassword':
          case 'forgotPasswordSubmit':
          case 'forgotPasswordSubmit_failure':
          case 'forgotPassword_failure':
            break;
          default:
            await fetchAuthUser();
        }
      },
    );

    // cleanup
    return () => {
      authListener();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * log user in
   * @param email
   * @param password
   */
  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    await Auth.signIn({ username: email, password });
  };

  /**
   * create new user account
   * @param email
   * @param password
   * @param firstName
   * @param lastName
   */
  const signUp = async ({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: `${firstName} ${lastName}`,
      },
    });
    setUnverifiedAccount({ email, password });
  };

  /**
   * confirm account using code
   * @param confirmCode
   * @returns {Promise<any>}
   */
  const confirmAccount = async ({ code }: { code: string }) => {
    await Auth.confirmSignUp(unverifiedAccount?.email, code);
    await signIn({
      email: unverifiedAccount?.email,
      password: unverifiedAccount?.password,
    });
  };

  /**
   * logout user
   */
  const signOut = async () => Auth.signOut();

  const changeUsername = (username: string) => {
    setUsername(username);
  };

  const value = {
    user,
    isAuthenticated: !!user,
    isAuthenticating,
    unverifiedAccount,
    signIn,
    signOut,
    signUp,
    confirmAccount,
  };

  if (isAuthenticating && authenticatedPaths.includes(router.pathname)) {
    return <Loader />;
  }

  if (user && !username) {
    return <Onboarding changeUsername={changeUsername} />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
