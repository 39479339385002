import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PolygonZkevm, PolygonZkevmTestnet } from '@thirdweb-dev/chains';
import { ThirdwebProvider } from '@thirdweb-dev/react';
import {
  IpfsUploader,
  StorageDownloader,
  ThirdwebStorage,
} from '@thirdweb-dev/storage';
import { Analytics } from '@vercel/analytics/react';
import type { NextPage } from 'next';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { env } from 'process';
import { ReactElement, ReactNode, useEffect } from 'react';
import React from 'react';

import { NextUIProvider, Toaster } from '@polyphonic/ui';

import { AuthProvider } from '@/contexts/auth.context';
import { inconsolata, spaceGrotesk, spartan } from '@/styles/fonts';
import '@/styles/globals.css';
import '@/styles/index.css';
import { toastStyles } from '@/styles/toaster';
import { isDev } from '@/utils';
import { api } from '@/utils/api';
import '@/utils/aws/config';
import * as gtag from '@/utils/gtag';
import SEO from '@/utils/seo.config';
import { dappMeta } from '@/utils/thirdweb/config';

export type NextPageWithLayout<P = NonNullable<unknown>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// Configure a custom ThirdwebStorage instance
const gatewayUrls = {
  'ipfs://': ['https://ipfs.io/ipfs/'],
};
const downloader = new StorageDownloader({});
const uploader = new IpfsUploader();
const storage = new ThirdwebStorage({ uploader, downloader, gatewayUrls });

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <NextUIProvider>
      <ThemeProvider
        attribute="class"
        disableTransitionOnChange
        defaultTheme="system"
      >
        <AuthProvider>
          <ThirdwebProvider
            clientId="3ddf3214cb4eaac4eeb2dd55bf3c98fd"
            activeChain={isDev() ? PolygonZkevmTestnet : PolygonZkevm}
            dAppMeta={dappMeta}
            sdkOptions={{
              alchemyApiKey: env.ALCHEMY_API_KEY,
            }}
            storageInterface={storage}
          >
            <main
              className={`${spaceGrotesk.variable} ${spartan.variable} ${inconsolata.variable} font-sans`}
            >
              <>
                {getLayout(
                  <>
                    <Toaster
                      position="top-center"
                      reverseOrder={false}
                      toastOptions={toastStyles}
                    />
                    <DefaultSeo {...SEO} />
                    <Component {...pageProps} />
                    <Analytics />
                  </>,
                )}
              </>
            </main>
            {isDev() && <ReactQueryDevtools initialIsOpen={false} />}
          </ThirdwebProvider>
        </AuthProvider>
      </ThemeProvider>
    </NextUIProvider>
  );
};

// export { reportWebVitals } from 'next-axiom';
export default api.withTRPC(App);
