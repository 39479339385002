import { zodResolver } from '@hookform/resolvers/zod';
import { IconLock } from '@tabler/icons-react';
import { Auth } from 'aws-amplify';
import { customAlphabet } from 'nanoid';
import Head from 'next/head';
import React, { useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button, CircularProgress, Input, toast } from '@polyphonic/ui';

import {
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  SPECIAL_CHARS_REGEX,
  UPPERCASE_REGEX,
} from '@/utils';
import { api } from '@/utils/api';

type SignupInput = {
  email: string;
  password: string;
  confirmpass: string;
};

type InviteInput = {
  betamail: string;
  betacode: string;
};

const SignupSchema = z
  .object({
    email: z
      .string()
      .trim()
      .min(1, { message: 'An email address is required' })
      .email('Please enter a valid email address'),
    password: z
      .string()
      .min(1, { message: 'Required' })
      .min(8, 'Password is too short')
      .max(32, 'Password is too long')
      .regex(LOWERCASE_REGEX, 'Atleast one lowercase character')
      .regex(NUMBER_REGEX, 'Atleast one uppercase character')
      .regex(SPECIAL_CHARS_REGEX, 'Atleast one special character')
      .regex(UPPERCASE_REGEX, 'Atleast one number'),
    confirmpass: z
      .string()
      .min(1, { message: 'Required' })
      .min(8, 'Password is too short')
      .max(32, 'Password is too long')
      .regex(LOWERCASE_REGEX, 'Atleast one lowercase character')
      .regex(NUMBER_REGEX, 'Atleast one uppercase character')
      .regex(SPECIAL_CHARS_REGEX, 'Atleast one special character')
      .regex(UPPERCASE_REGEX, 'Atleast one number'),
  })
  .refine((data) => data.password === data.confirmpass, {
    message: 'Passwords must match',
    path: ['confirmpass'],
  });

const InviteSchema = z.object({
  betamail: z
    .string()
    .trim()
    .min(1, { message: 'An email address is required' })
    .email('Please enter a valid email address'),
  betacode: z.string().min(1, { message: 'Please enter a code' }),
});

interface AuthErrorMessage {
  message: string;
  log?: string;
}

export const Signup = () => {
  const {
    handleSubmit,
    register,
    watch,

    formState: { errors },
  } = useForm<SignupInput>({
    resolver: zodResolver(SignupSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit: inviteSubmit,
    register: inviteRegister,

    formState: { errors: inviteErrors },
  } = useForm<InviteInput>({
    resolver: zodResolver(InviteSchema),
    mode: 'onChange',
  });

  const password = useRef({});
  password.current = watch('password', '');
  const confirmpass = useRef({});
  confirmpass.current = watch('confirmpass', '');

  const [invite, setInvite] = useState(false);

  const alphabet = '123456789abcdef';
  const nanoid = customAlphabet(alphabet, 40);

  const handleSignup: SubmitHandler<SignupInput> = async ({
    email,
    password,
  }) => {
    const cognitoUsername = '00' + nanoid();
    const validationData = {
      email: email,
    };
    try {
      setIsSubmitting(true);
      await Auth.signUp({
        username: cognitoUsername,
        password: password,
        attributes: {
          email: email,
        },
        validationData,
      });
      setIsSubmitting(false);
      toast.success('Signup success. Please verify your email address.');
    } catch (error) {
      const typedError = error as AuthErrorMessage;
      setIsSubmitting(false);
      switch (typedError.message) {
        case 'PreSignUp failed with error Email already exists.':
          toast.error('Email already exists');
          break;
        case 'PreSignUp failed with error No invite found.':
          toast.error('No invite found');
          break;
        case 'PreSignUp failed with error Email address is already in use.':
          toast.error('Email address is already in use.');
          break;
        default:
          toast.error(typedError.message);
      }
    }
  };
  const [inviteGate, setInviteGate] = useState<[string, string]>(['', '']);
  const { refetch } = api.account.invite.useQuery(
    {
      email: inviteGate[0],
      code: inviteGate[1],
    },
    {
      enabled: false,
    },
  );

  const handleInvite: SubmitHandler<InviteInput> = async ({
    betamail,
    betacode,
  }) => {
    setIsSubmitting(true);
    await setInviteGate([betamail, betacode]);
    await refetch().then((res) => {
      if (res?.data?.found) {
        setIsSubmitting(false);
        toast.success(res.data.message);
        setInvite(true);
      } else {
        setIsSubmitting(false);
        toast.error(res?.data?.message || 'Something went wrong');
      }
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      {invite ? (
        <>
          <Head>
            <title>Polyphonic | Sign Up</title>
          </Head>
          <div className="flex flex-end items-center flex-col w-full p-3">
            <p className="mt-3 text-center">Create an account</p>
            <form onSubmit={handleSubmit(handleSignup)} className="mt-3 w-full">
              <Input
                fullWidth
                id="email"
                label="Email"
                errorMessage={errors.email?.message}
                className="mb-3"
                type="text"
                {...register('email')}
                color="primary"
                variant="faded"
              />
              <Input
                fullWidth
                id="password"
                label="Password"
                errorMessage={errors.password?.message}
                className="mb-3"
                {...register('password')}
                type="password"
                color="primary"
                variant="faded"
              />
              <Input
                fullWidth
                id="confirmpass"
                label="Confirm Password"
                errorMessage={errors.confirmpass?.message}
                className="mb-3"
                {...register('confirmpass')}
                type="password"
                color="primary"
                variant="faded"
              />
              <Button className="w-full text-center" type="submit">
                {isSubmitting ? (
                  <CircularProgress
                    strokeWidth={3}
                    aria-label="Progress indicator"
                  />
                ) : (
                  'Sign Up'
                )}
              </Button>
            </form>
          </div>
        </>
      ) : (
        <>
          <Head>
            <title>Polyphonic | Invite</title>
          </Head>
          <div className="flex justify-around items-center flex-col w-full mt-2">
            <IconLock />
            <p className="text-center m-2">Enter Invite Details</p>
            <form
              className="mt-6 w-full flex flex-col"
              onSubmit={inviteSubmit(handleInvite)}
            >
              <Input
                fullWidth
                size="sm"
                id="betamail"
                label="Email Address"
                errorMessage={inviteErrors.betamail?.message}
                className="mb-3"
                {...inviteRegister('betamail')}
                variant="faded"
              />
              <Input
                fullWidth
                size="sm"
                id="betacode"
                label="Invite Code"
                errorMessage={inviteErrors.betacode?.message}
                className="mb-3"
                {...inviteRegister('betacode')}
                variant="faded"
              />
              <Button
                className="mt-3 w-full text-center rounded-full bg-primary text-white font-semibold"
                type="submit"
                isLoading={isSubmitting}
              >
                Unlock
              </Button>
            </form>
          </div>
        </>
      )}
    </>
  );
};
