export const authenticatedPaths = [
  '/dashboard',
  '/links',
  '/distribution',
  '/agreements',
  '/settings',
];

export const marketplaceAddress = process.env.NEXT_PUBLIC_CONTRACT_ADDRESS;

export const alchemyId = process.env.ALCHEMY_ID;

export const coinmarketcapAPIKey = process.env.COINMARKETCAP_API_KEY;

export const PASSWORD_REGEX = new RegExp(
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
);

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 uppercase letter, 1 lowercase letter, a number, a special character and be longer than 8 characters';

export const LOWERCASE_REGEX = new RegExp('^(?=.*[a-z])');

export const UPPERCASE_REGEX = new RegExp('^(?=.*[A-Z])');

export const NUMBER_REGEX = new RegExp('^(?=.*[0-9])');

export const SPECIAL_CHARS_REGEX = new RegExp('^(?=.*?[#?!@$%^&*-])');

export const USERNAME_REGEX = new RegExp('^[A-Za-z0-9-]*$');

export enum AgreementResponse {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export enum S3AccessLevel {
  PUBLIC = 'PUBLIC',
  PROTECTED = 'PROTECTED',
  PRIVATE = 'PRIVATE',
}

export enum Plan {
  BETA = 'BETA',
  FREE = 'FREE',
  PLUS = 'PLUS',
  PRO = 'PRO',
}

export enum AgreementType {
  ROYALTY_SPLIT_AGREEMENT = 'ROYALTY_SPLIT_AGREEMENT',
  LICENSING_AGREEMENT = 'LICENSING_AGREEMENT',
  BOOKING_AGREEMENT = 'BOOKING_AGREEMENT',
}

export enum Association {
  ARTIST = 'ARTIST',
  COLLABORATOR = 'COLLABORATOR',
  LICENSEE = 'LICENSEE',
  PROMOTER = 'PROMOTER',
}

export enum MediaKind {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  OTHER = 'OTHER',
}

export enum Blockchain {
  ETHEREUM = 'ETHEREUM',
  POLYGON = 'POLYGON',
}

export enum TokenStandard {
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
}
