import { customAlphabet } from 'nanoid';

const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz';
const LENGTH = 12;

export enum Prefix {
  REL = 'REL',
  PRO = 'PRO',
}

/**
 * Description: Create a prefixed ID
 *
 * @param  {Prefix} type
 * @returns number
 */
export const id = (type: Prefix) => {
  const nanoid = customAlphabet(ALPHABET, LENGTH);
  return `${type}-${nanoid()}`;
};
